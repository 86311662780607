import Swal from "sweetalert2"

import "../css/formulariosPredefined.css"


export const succesSwal = (title, body, buttonText, callback) => {
    Swal.fire({
        title: `<p class="clr-swal mb-0 bold-title-hyundai font-res f-responsive-14 mb-0">${title}</p>`,
        icon: 'success',
        html:
          `<p class="clr-swal parrafo-hyundai f-responsive">${body}</p>`,
        showCloseButton: false,
        showCancelButton: false,
        confirmButtonColor: '#37719f',
        confirmButtonText: buttonText,
        timer:4000
      }).then((result) => {
        if (result.isConfirmed) {
          if(callback) callback();
        }
      }).catch((error) => {
        
      })
}

export const errorSwal = (title, body, buttonText, callback) => {
    Swal.fire({
        title: `<p class="clr-swal mb-0 bold-title-hyundai f-responsive-14">${title}</p>`,
        icon: 'error',
        html:
          `<p class="clr-swal parrafo-hyundai f-responsive">${body}</p>`,
        showCloseButton: false,
        showCancelButton: false,
        confirmButtonText: buttonText,
      }).then(() => {
          if(callback) callback();
      })
}

export const infoSwal = (title, body, buttonText, callback) => {
  Swal.fire({
      title: `<p class="clr-swal mb-0 bold-title-hyundai f-responsive-14">${title}</p>`,
      icon: 'info',
      html:
        `<p class="clr-swal parrafo-hyundai f-responsive">${body}</p>`,
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText: buttonText,
    }).then(() => {
        if(callback) callback();
    })
}

export const redireccionSwal = (title, body, buttonText, direccion) => {
  Swal.fire({
      title: `<p class="clr-swal mb-0 bold-title-hyundai f-responsive-14">${title}</p>`,
      icon: 'success',
      html:
        `<p class="clr-swal parrafo-hyundai f-responsive">${body}</p>`,
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonText: buttonText,
    }).then(() => {
       window.location.href = direccion
    })
}

export const loginSwal = (title, body, buttonText, direccion) => {
  Swal.fire({
      title: `<p class="clr-swal mb-0 bold-title-hyundai f-responsive-14">${title}</p>`,
      icon: 'success',
      html:
        `<p class="clr-swal parrafo-hyundai f-responsive">${body}</p>`,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      confirmButtonText: buttonText,
      timer:1500
    }).then(() => {
       window.location.href = direccion
    })
}

export const compraSwal = (title, body) => {
  Swal.fire({
      title: `<p class="clr-swal mb-0 bold-title-hyundai mb-0 f-responsive-14">${title}</p>`,
      icon: 'success',
      html:
        `<p class="clr-swal parrafo-hyundai f-responsive">${body}</p>`,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#37719f',
      cancelButtonColor: '#37719f',
      confirmButtonText: 'Ir al carro de compras',
      cancelButtonText: 'Seguir comprando',
      
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/carrito"
      }
    }).catch((error) => {
      
    })
}

export const loadingPeticion = () =>{
  Swal.fire({
    title: 'Guardando cambios',
  }).then((result) => {
    
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log('I was closed by the timer')
    }
  })
}


export const pasoSwal = (title, body,link) => {
  Swal.fire({
      title: `<p class="clr-swal mb-0 bold-title-hyundai mb-0 f-responsive-14">${title}</p>`,
      icon: 'success',
      html:
        `<p class="clr-swal parrafo-hyundai f-responsive">${body}</p>`,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#37719f',
      cancelButtonColor: '#37719f',
      confirmButtonText: 'Ir a vehículos',
      cancelButtonText: 'Continuar',
      
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = `/mantenedor/${link}`
      }
    }).catch((error) => {
      
    })
}