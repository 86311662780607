
import clienteAxios from "../../../configAxios/configAxios"
import { errorSwal, redireccionSwal } from "../../../helper/swalHelpers";
import { CERRAR_SESION, INICIAR_SESION_CORRECTO } from "../types/loginTypes";

export const logearUsuario = (usuario) => {
    return async (dispatch) => {
        try {
            let response = await clienteAxios.post('Cliente/iniciarSesion', usuario);

            if (response.status === 200) {
                let data = response.data.data;
                if (data.RSPTA === 0) {
                    dispatch(loginExitoso(data))
                    window.sessionStorage.setItem("usuario", JSON.stringify(data));
                    window.location.href = "/";
                    return
                }
            }
            errorSwal("Ocurrió un error", "Correo o contraseña incorrectos", "Ok");

        } catch (error) {
            errorSwal("Ocurrió un error", "Ocurrió un error, por favor inténtalo de nuevo", "Ok");
        }
    }
};

export const cambiarPassword = (datos) => {
    return async () => {
        try {
            let response = await clienteAxios.put('Cliente/cambiarpassword', datos);

            if (response.status === 200) {
                let data = response.data.data;
                if (data[0].RSPTA === 0) {
                    redireccionSwal("Contraseña cambiada", "Contraseña cambiada satisfactoriamente.", "Ok", "/perfil")
                    return;
                }
            }
            errorSwal("Ocurrió un error", "Contraseñas incorrectas", "Ok");
        } catch (error) {
            errorSwal("Ocurrió un error", "Ocurrió un error, por favor inténtalo de nuevo", "Ok");
        }
    }
}

export const loginCarrito = (datos) => {
    return async (dispatch) => {
        try {
            let response = await clienteAxios.post('Cliente/iniciarSesion', datos);
            if (response.status === 200) {
                let data = response.data.data;
                if (data.RSPTA === 0) {
                    dispatch(loginExitoso(data))
                    window.sessionStorage.setItem("usuario", JSON.stringify(data));
                    return data;
                }
            }
            errorSwal("Ocurrió un error", "Correo o contraseña incorrectos", "Ok");
            return false;

        } catch (error) {
            errorSwal("Ocurrió un error", "Ocurrió un error, por favor inténtalo de nuevo", "Ok");
            return false;
        }
    }
}

export const actualizarDatos = (datos) => {
    return async () => {
        try {
            let response = await clienteAxios.put('cliente/actualizar', datos);

        } catch (error) {

        }
    }
}

export const logOut = () => {
    return async (dispatch) => {
        sessionStorage.removeItem("usuario");
        dispatch(cerrarSesion());
        window.location.href = "/";
    }
}

export const guardarDatosUsuario = (usuario) => {
    return async (dispatch) => {
        dispatch(loginExitoso(usuario))
    }
}

const cerrarSesion = () => ({
    type: CERRAR_SESION
})

const loginExitoso = usuario => ({
    type: INICIAR_SESION_CORRECTO,
    payload: usuario
})

export const clienteValidarToken = (idCliente, Token) => {
    return async () => {
        let obj = {
            idCliente: parseInt(idCliente),
            Token: Token
        }
        try {
            let response = await clienteAxios.post('Cliente/validarToken', obj);

            if (response.status === 200) {
                let data = response.data.data;
                if (data.RSPTA === 0) {
                    return data;
                }
            }
            errorSwal("Ocurrió un error", "Token expirado o no válido", "Ok");

        } catch (error) {
            errorSwal("Ocurrió un error", "Ocurrió un error, por favor inténtalo de nuevo", "Ok");
        }
    }
};