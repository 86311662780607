import clienteAxios from "../../../configAxios/configAxios";
import { errorSwal, loginSwal } from "../../../helper/swalHelpers";
import { CERRAR_SESION_BACK, INICIAR_SESION_CORRECTO_BACK } from "../types/loginTypes";

export const logearUsuario = (usuarioBackoffice) => {
    return async (dispatch) => {
        try {
            let response = await clienteAxios.post('Usuario/iniciarSesion', usuarioBackoffice);
            console.log("logearUsuario -> response", response)

            if (response.status === 200) {
                let data = response.data.data;
                
                if (data.RSPTA === 0) {

                    dispatch(loginExitosoBack(data))
                    console.log("logearUsuario -> data", data)

                    window.sessionStorage.setItem("usuarioBackoffice", JSON.stringify(data));
                    
                    loginSwal("Bienvenido", "Has iniciado sesión correctamente", "Ok", "/mantenedor/bienvenido")
                    return
                } else{
                    
                    errorSwal("Ocurrió un error", "Correo o contraseña incorrectos", "Ok");
                }
            }

        } catch (error) {
            errorSwal("Ocurrió un error", "Ocurrió un error, por favor inténtalo de nuevo", "Ok");
        }
    }
};

export const loggedOut = () => {
    return async (dispatch) => {
        sessionStorage.removeItem("usuarioBackoffice");
        dispatch(cerrarSesion());
        window.location.href = "/mantenedor/login";
    }
}

export const guardarDatosUsuarioBackoffice = (usuarioBackoffice) => {
    return async (dispatch) => {
        dispatch(loginExitosoBack(usuarioBackoffice))
    }
}

const cerrarSesion = () => ({
    type: CERRAR_SESION_BACK
})

const loginExitosoBack = usuarioBackoffice => ({
    type: INICIAR_SESION_CORRECTO_BACK,
    payload: usuarioBackoffice
})