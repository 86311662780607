import React from 'react';

function LoaderContainerMobile() {

    return (
        <div className="row w-100 h-100 position-absolute">
            <div className="col-12 h-100 p-5 text-center">
                <div className="spinner-grow commercialBlue position-absolute" style={{top:"50%"}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    )
    
}

export default LoaderContainerMobile;