import { CERRAR_SESION, INICIAR_SESION_CORRECTO } from "../types/loginTypes";

const initialState = {
    autenticado: false,
    user:{}
}

export default function(state = initialState, action){
    switch (action.type) {
        case INICIAR_SESION_CORRECTO:
            return{
                ...state,
                autenticado: true,
                user: action.payload
            }
        case CERRAR_SESION:
            return{
                autenticado: false,
                user:{}
            }
        default:
            return state;
    }
}