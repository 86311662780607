import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const RutaPrivada = ({ component: Component, ...props }) => {
  //Revisamos si el usuario esta loggeado
  const logged = useSelector((state) => state.login.autenticado);

  return (
    <Route
      {...props} render={(props) => logged === false ? (
          //Si no esta loggeado lo redireccionamos al home
          <Redirect to="/" />

        ) : (
          //Si esta loggeado lo llevamos a la ruta que especificamos en el Ruteo
          <Component {...props} />
        )
      }
    />
  );
};

export default RutaPrivada;
