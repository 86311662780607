import { AGREGAR_QUERY } from "../types/queryTypes";

const initialState = {
    search: ""
}

export default function(state = initialState, action){
    switch (action.type) {
        case AGREGAR_QUERY:
            return{
                ...state,
                search: action.payload
            }
        default:
            return state;
    }
}