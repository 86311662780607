import React, { Suspense, lazy } from 'react';
import { useDispatch } from 'react-redux';
import { guardarDatosUsuario } from '../Front/redux/actions/loginActions';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoaderContainerMobile from "../Front/components/loader/LoaderContainerMobile";
import { guardarDatosUsuarioBackoffice } from '../BackOffice/redux/actions/loginActions';
import PrivateRouteAdmin from "../privateRoute/privateRouteAdmin";
import RutaPrivada from "../privateRoute";
import ScrollToTop from "../Front/components/ScrollToTop";
import RedirectWithStatus from "./redirectStatus";

const Home = lazy(() => import("../Front/views/home/home"));
const LoginPersonas = lazy(() => import("../Front/views/login/loginPersonas"));
const RecuperarPassword = lazy(() => import('../Front/views/login/recuperarPassword'));
const ReiniciarPassword = lazy(() => import('../Front/views/login/reiniciarPassword'));
const AgendarServicio = lazy(() => import("../Front/views/agendarServicio/agendarServicio"));
const PaginaError = lazy(() => import("../Front/views/404/paginaError"));
const Buscar = lazy(() => import("../Front/views/buscar/buscar"));
const Perfil = lazy(() => import("../Front/views/perfil/perfil"));
const Registrarse = lazy(() => import("../Front/views/registrarse/registrarse"));
const Contactanos = lazy(() => import("../Front/views/contactanos/contactanos"));
const Noticia = lazy(() => import("../Front/views/noticia/noticia"));
const Flotas = lazy(() => import("../Front/views/flotas/flotas"));
const Nosotros = lazy(() => import("../Front/views/nosotros/nosotros"));
const FichaModelo = lazy(() => import("../Front/views/fichaModelo/fichaModelo"));
const Vehiculos = lazy(() => import("../Front/views/vehiculos/vehiculos"));
const Cotizar = lazy(() => import("../Front/views/cotizar"));
const FichaRepuesto = lazy(() => import("../Front/views/fichaRepuesto/fichaRepuesto"));
const FichaVehiculo = lazy(() => import("../Front/views/fichaVehiculo/fichaVehiculo"));
const Outlet = lazy(() => import("../Front/views/outlet/outlet"));
const PublicacionPrensa = lazy(() => import("../Front/views/publicacionPrensa/publicacionPrensa"));
const Prensa = lazy(() => import("../Front/views/prensa/prensa"));
const FichaMercadoPublico = lazy(() => import("../Front/views/fichaMercadoPublico/fichaMercadoPublico"));
const MercadoPublico = lazy(() => import("../Front/views/mercadoPublico/mercadoPublico"));
const ServicioTecnico = lazy(() => import("../Front/views/servicioTecnico/servicioTecnico"));
const AtencionCliente = lazy(() => import("../Front/views/atencionCliente/atencionCliente"));
const Concesionarios = lazy(() => import("../Front/views/concesionario/Concesionarios"));
const Concesionario = lazy(() => import("../Front/views/concesionario/Ficha"))
const Politicas = lazy(() => import("../Front/views/politicas/politicas"));
const Carrito = lazy(() => import("../Front/views/carrito"));
const CarritoFinalizado = lazy(() => import("../Front/views/carrito/finalizado/carritoFinalizado"));
const CarritoRechazado = lazy(() => import("../Front/views/carrito/rechazado/carritoRechazado"));
const SolicitarGarantia = lazy(() => import("../Front/views/solicitarGarantia"))
const Repuestos = lazy(() => import("../Front/views/repuestos"))
const SolicitarRepuesto = lazy(() => import("../Front/views/solicitarRepuesto"))
const Listo = lazy(() => import("../Front/views/cotizar/listo"));
const Rechazado = lazy(() => import("../Front/views/cotizar/rechazado/rechazado"));
const Login = lazy(() => import("../BackOffice/view/login/Login"));

const Oficinas = lazy(() => import("../BackOffice/view/Mantenedores/oficina/oficinas"));
const NuevaOficina = lazy(() => import("../BackOffice/view/Mantenedores/oficina/nuevaOficina"));
const EditarOficina = lazy(() => import("../BackOffice/view/Mantenedores/oficina/editarOficina"));
const ContactanosBackoffice = lazy(() => import("../BackOffice/view/Mantenedores/contactanos/contactanos"));

const WhitePage = lazy(() => import("../BackOffice/view/Mantenedores/whitePage/whitePage"));
const servicioCliente = lazy(() => import("../BackOffice/view/Mantenedores/servicioCliente/servicioCliente"));
const servicioTecnicoF = lazy(() => import("../BackOffice/view/Mantenedores/servicioTecnicoF/contenedor"));
const concesionarioF = lazy(() => import("../BackOffice/view/Mantenedores/concesionarioF/agendaServicio"));
const flotasF = lazy(() => import("../BackOffice/view/Mantenedores/flotasF/flotasF"));
const mercadoPublicoF = lazy(() => import("../BackOffice/view/Mantenedores/mercadoPublicoF/mercadoPublicoF"));
const repuestosF = lazy(() => import("../BackOffice/view/Mantenedores/repuestosF/contenedor"));
const Cotizaciones = lazy(() => import("../BackOffice/view/Mantenedores/cotizaciones/cotizaciones"));
const cotizadorRepuesto = lazy(() => import("../BackOffice/view/Mantenedores/cotizadorRepuesto/cotizadorRepuesto"));
const cotizadorMantencion = lazy(() => import("../BackOffice/view/Mantenedores/cotizadorMantencion/cotizadorMantencion"));
const UsuariosF = lazy(() => import("../BackOffice/view/Mantenedores/usuariosRegistrados/usuariosRegistrados"));
const editarUsuarioF = lazy(() => import("../BackOffice/view/Mantenedores/usuariosRegistrados/editarUsuarioF/editarUsuarioF"));
const UsuariosRegistrados = lazy(() => import("../BackOffice/view/Mantenedores/usuarios/usuariosRegistrados"));
const PerfilesF = lazy(() => import("../BackOffice/view/Mantenedores/perfiles/perfiles"));
const crearPerfilF = lazy(() => import("../BackOffice/view/Mantenedores/perfiles/crearPerfil/crearPerfil"));
const editarPerfilF = lazy(() => import("../BackOffice/view/Mantenedores/perfiles/editarPerfil/editarPerfil"));
const CrearUsuarioF = lazy(() => import("../BackOffice/view/Mantenedores/usuariosRegistrados/crearUsuarioF/crearUsuarioF"));
const ConcesionariosHome = lazy(() => import("../BackOffice/view/Mantenedores/concesionario/Concesionarios"));
const Historias = lazy(() => import("../BackOffice/view/Mantenedores/historias/historias"));
const HistoriasNew = lazy(() => import("../BackOffice/view/Mantenedores/historias/crearHistoria/crearHistoria"));
const HistoriasEditar = lazy(() => import("../BackOffice/view/Mantenedores/historias/editarHistoria/editarHistoria"));
const Noticias = lazy(() => import("../BackOffice/view/Mantenedores/noticias/noticias"));
const NoticiasNew = lazy(() => import("../BackOffice/view/Mantenedores/noticias/crearNoticia/crearNoticia"));
const NoticiasEditar = lazy(() => import("../BackOffice/view/Mantenedores/noticias/editarNoticia/editarNoticia"));
const Clientes = lazy(() => import("../BackOffice/view/Mantenedores/clientes/clientes"));
const ClientesNew = lazy(() => import("../BackOffice/view/Mantenedores/clientes/crearCliente/crearCliente"));
const ClientesEditar = lazy(() => import("../BackOffice/view/Mantenedores/clientes/editarCliente/editarCliente"));
const Banner = lazy(() => import("../BackOffice/view/Mantenedores/banner/bannerSlider"));
const MantenedorFlotas = lazy(() => import("../BackOffice/view/Mantenedores/flotas/flotasIndex"));
const MantenedorFlotasNuevoContenido = lazy(() => import("../BackOffice/view/Mantenedores/flotas/nuevoContenido"));
const MantenedorFlotasEditarContenido = lazy(() => import("../BackOffice/view/Mantenedores/flotas/editarContenido"));
const ServicioTecnicoSos = lazy(() => import("../BackOffice/view/Mantenedores/servicioTecnico/sos"));
const SosNuevoContenido = lazy(() => import("../BackOffice/view/Mantenedores/servicioTecnico/nuevoContenido"));
const SosEditarContenido = lazy(() => import("../BackOffice/view/Mantenedores/servicioTecnico/editarContenido"));
const Recalls = lazy(() => import('../BackOffice/view/Mantenedores/servicioTecnico/recalls'));
const RepuestosTodos = lazy(() => import("../BackOffice/view/Mantenedores/repuestos/todos"));
const VehiculosMantenedor = lazy(() => import("../BackOffice/view/Mantenedores/vehiculos"));
const CrearVehiculo = lazy(() => import("../BackOffice/view/Mantenedores/vehiculos/crear"));
const EditarVehiculo = lazy(() => import("../BackOffice/view/Mantenedores/vehiculos/editar"));
const DuplicarVehiculo = lazy(() => import("../BackOffice/view/Mantenedores/vehiculos/duplica"));
const OutletMantenedor = lazy(() => import("../BackOffice/view/Mantenedores/outlet"));

const CrearVehiculoOutlet = lazy(() => import("../BackOffice/view/Mantenedores/outlet/crear"));
const NuevoRepuesto = lazy(() => import("../BackOffice/view/Mantenedores/repuestos/nuevoRepuesto"));
const CopiaRepuesto = lazy(() => import("../BackOffice/view/Mantenedores/repuestos/copiaRepuesto"));
const EditarRepuesto = lazy(() => import("../BackOffice/view/Mantenedores/repuestos/editarRepuesto"));
const OutletRepuestos = lazy(() => import("../BackOffice/view/Mantenedores/outletRepuestos/repuestos"));
const NuevoRepuestoOutlet = lazy(() => import("../BackOffice/view/Mantenedores/outletRepuestos/nuevoRepuesto"));
const EditarRepuestoOutlet = lazy(() => import("../BackOffice/view/Mantenedores/outletRepuestos/editarRepuesto"));
const CopiaRepuestoOutlet = lazy(() => import("../BackOffice/view/Mantenedores/outletRepuestos/copiaRepuestoOutlet"));
const HomeMantenedor = lazy(() => import("../BackOffice/view/Mantenedores/home/home"));
const TransaccionesRepuestos = lazy(() => import("../BackOffice/view/Mantenedores/repuestosTransacciones/transacciones"));
const ReservaLead = lazy(() => import("../BackOffice/view/Mantenedores/vehiculosTransaccionesOutlet/reservaLead"));
const ReservaEnLinea = lazy(() => import("../BackOffice/view/Mantenedores/vehiculosTransaccionesOutlet/reservaEnLinea"));
const GarantiaMantencion = lazy(() => import('../BackOffice/view/Mantenedores/garantiaMantencion/garantiaMantencion'));
const NuevaGarantiaMantencion = lazy(() => import('../BackOffice/view/Mantenedores/garantiaMantencion/nuevaGarantiaMantencion'));
const EditarGarantiaMantencion = lazy(() => import('../BackOffice/view/Mantenedores/garantiaMantencion/editarGarantiaMantencion'));
const CopiarGarantiaMantencion = lazy(() => import('../BackOffice/view/Mantenedores/garantiaMantencion/copiarGarantiaMantencion'));
const PoliticasSeguridad = lazy(() => import("../BackOffice/view/Mantenedores/politicasTerminos/politicas"));
const DerechoRetracto = lazy(() => import('../BackOffice/view/Mantenedores/derechoRetracto/derechoRetracto'));
const TerminosCondiciones = lazy(() => import("../BackOffice/view/Mantenedores/politicasTerminos/terminos"));
const MercadoPublicoCrear = lazy(() => import('../BackOffice/view/Mantenedores/mercadoPublico/crear/index'));
const Footer = lazy(() => import('../BackOffice/view/Mantenedores/footer/footer'))

const Routes = () => {
    const dispatch = useDispatch();

    if (localStorage.getItem('productos') === null) {
        localStorage.setItem('productos', JSON.stringify([]))
    }
    else {
        let car = JSON.parse(localStorage.getItem('productos')) !== null ? JSON.parse(localStorage.getItem('productos')) : [];
        let isVehiculo = car.some(producto => producto.tipo === "vehiculo");
        if (isVehiculo === true) localStorage.setItem('productos', JSON.stringify([]));
    }

    if (sessionStorage.getItem('usuario')) {
        let user = JSON.parse(sessionStorage.getItem('usuario'));

        const ingresarUsuario = usuario => dispatch(guardarDatosUsuario(usuario));
        ingresarUsuario(user);
    }

    if (sessionStorage.getItem('usuarioBackoffice')) {
        let userBackoffice = JSON.parse(sessionStorage.getItem('usuarioBackoffice'));

        const ingresarUsuarioBackoffice = usuarioBackoffice => dispatch(guardarDatosUsuarioBackoffice(usuarioBackoffice));
        ingresarUsuarioBackoffice(userBackoffice);
    }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Suspense fallback={<LoaderContainerMobile />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={LoginPersonas} />
                    <Route exact path="/recuperar-contrasena" component={RecuperarPassword} />
                    <Route exact path="/reiniciar-contrasena/:idCliente/:Token" component={ReiniciarPassword} />
                    <Route exact path="/registrarse" component={Registrarse} />
                    <RutaPrivada exact path="/perfil/:lugar?" component={Perfil} />
                    <Route exact path="/buscar" component={Buscar} />
                    <Route exact path="/vehiculos/:seccion?" component={Vehiculos} />
                    <Route exact path="/vehiculo/:slug" component={FichaModelo} />
                    <Route exact path="/concesionarios" component={Concesionarios} />
                    <Route exact path="/concesionario/:slugCon/:slugOfi/:irMapa?" component={Concesionario} />
                    <Route exact path="/nosotros/:seccion?" component={Nosotros} />
                    <Route exact path="/nosotros/noticias/:slug" component={Noticia} />
                    <Route exact path='/atencion-al-cliente' component={AtencionCliente} />
                    <Route exact path='/servicio-tecnico/:seccion?' component={ServicioTecnico} />
                    <Route exact path="/servicio-tecnico/campanas-y-recalls/:slug" component={Noticia} />
                    <Route exact path='/mercado-publico' component={MercadoPublico} />
                    <Route exact path='/mercado-publico/:slug' component={FichaMercadoPublico} />
                    {/* <Route exact path='/prensa' component={Prensa} />
                    <Route exact path='/prensa/:slug' component={PublicacionPrensa} /> */}
                    {/* OUTLET OFF */}
                    <Route exact path="/outlet/:seccion" component={Outlet} />
                    <Route exact path='/outlet/vehiculo/:slug' component={FichaVehiculo} />
                    <Route exact path='/outlet/repuesto/:slug' component={FichaRepuesto} />
                    <Route exact path='/repuesto/:slug' component={FichaRepuesto} />
                    <Route exact path='/cotizar/:seccion?/:slug?/:slugversion?/:cantidad?' component={Cotizar} />
                    <Route exact path='/cotizacion/ok' component={Listo} />
                    <Route exact path='/solicitud/ok' component={Listo} />
                    <Route exact path='/cotizacion/rechazado' component={Rechazado} />
                    <Route exact path='/solicitud/rechazado' component={Rechazado} />
                    <Route exact path='/flotas' component={Flotas} />
                    <Route exact path='/contactanos' component={Contactanos} />
                    <Route exact path='/404' component={PaginaError} />
                    <Route exact path='/agenda-tu-servicio' component={AgendarServicio} />
                    <Route exact path='/terminos' component={() => <Politicas tipo="TERMINOS" />} />
                    <Route exact path='/politicas' component={() => <Politicas tipo="POLITICAS" />} />
                    <Route exact path='/derechos-del-consumidor/:seccion?' component={() => <Politicas tipo="DERECHO_RETRACTO" />} />
                    <Route exact path='/carrito' component={Carrito} />
                    <Route exact path='/compra/finalizado' component={CarritoFinalizado} />
                    <Route exact path='/compra/rechazado' component={CarritoRechazado} />
                    <Route exact path='/solicitar-garantia' component={SolicitarGarantia} />
                    <Route exact path='/repuestos' component={Repuestos} />
                    <Route exact path='/solicitar-repuesto' component={SolicitarRepuesto} />

                    {/* BackOffice */}{/* BackOffice */}{/* BackOffice */}{/* BackOffice */}{/* BackOffice */}{/* BackOffice */}{/* BackOffice */}
                    {/* BackOffice */}{/* BackOffice */}{/* BackOffice */}{/* BackOffice */}{/* BackOffice */}{/* BackOffice */}{/* BackOffice */}

                    <Route exact path='/mantenedor/login' component={Login} />
                    <PrivateRouteAdmin exact permisos={['*']} restricted={true} path='/mantenedor/bienvenido' component={WhitePage} />

                    {/* PANTALLAS */}
                    <PrivateRouteAdmin exact permisos={['HOME']} path='/mantenedor/home' component={HomeMantenedor} />
                    <PrivateRouteAdmin exact permisos={['VEHICULOS']} path="/mantenedor/vehiculos" component={VehiculosMantenedor} />
                    <PrivateRouteAdmin exact permisos={['VEHICULOS']} path='/mantenedor/vehiculos/crear' component={CrearVehiculo}/>
                    <PrivateRouteAdmin exact permisos={['VEHICULOS']} path="/mantenedor/vehiculos/editar/:idVehiculo" component={EditarVehiculo}/>
                    <PrivateRouteAdmin exact permisos={['VEHICULOS']} path="/mantenedor/vehiculos/duplicar/:idVehiculo" component={DuplicarVehiculo}/>
                    <PrivateRouteAdmin exact permisos={['CONCESIONARIOS']} path='/mantenedor/concesionarios' component={ConcesionariosHome} />
                    <PrivateRouteAdmin exact permisos={['CONCESIONARIOS']} path='/mantenedor/oficinas' component={Oficinas} />
                    <PrivateRouteAdmin exact permisos={['CONCESIONARIOS']} path='/mantenedor/oficina/editar/:id' component={EditarOficina} />
                    <PrivateRouteAdmin exact permisos={['CONCESIONARIOS']} path='/mantenedor/oficina/crear' component={NuevaOficina} />)
                    <PrivateRouteAdmin exact permisos={['GARANTIA']} path='/mantenedor/garantiaymantencion' component={GarantiaMantencion} />
                    <PrivateRouteAdmin exact permisos={['GARANTIA']} path='/mantenedor/garantiaymantencion/nueva' component={NuevaGarantiaMantencion} />
                    <PrivateRouteAdmin exact permisos={['GARANTIA']} path='/mantenedor/garantiaymantencion/editar/:id' component={EditarGarantiaMantencion} />
                    <PrivateRouteAdmin exact permisos={['GARANTIA']} path='/mantenedor/garantiaymantencion/copiar/:id' component={CopiarGarantiaMantencion} />
                    <PrivateRouteAdmin exact permisos={['SOS']} path='/mantenedor/serviciotecnico/soshyundai' component={ServicioTecnicoSos} />
                    <PrivateRouteAdmin exact permisos={['SOS']} path='/mantenedor/serviciotecnico/soseditarcontenido/:idSeccion' component={SosEditarContenido} />
                    <PrivateRouteAdmin exact permisos={['SOS']} path='/mantenedor/serviciotecnico/sosnuevocontenido' component={SosNuevoContenido} />
                    <PrivateRouteAdmin exact permisos={['SOS']} path='/mantenedor/serviciotecnico/recalls' component={Recalls} />
                    <PrivateRouteAdmin exact permisos={['SOS']} path='/mantenedor/serviciotecnico/recalls/crear' component={NoticiasNew} />
                    <PrivateRouteAdmin exact permisos={['SOS']} path='/mantenedor/serviciotecnico/recalls/editar/:idArticulo' component={NoticiasEditar} />
                    <PrivateRouteAdmin exact permisos={['REPUESTOS']} path='/mantenedor/repuestos' component={RepuestosTodos} />
                    <PrivateRouteAdmin exact permisos={['REPUESTOS']} path='/mantenedor/repuesto/crear' component={NuevoRepuesto} />
                    <PrivateRouteAdmin exact permisos={['REPUESTOS']} path='/mantenedor/repuesto/duplicar/:idRepuesto' component={CopiaRepuesto} />
                    <PrivateRouteAdmin exact permisos={['REPUESTOS']} path='/mantenedor/repuesto/editar/:idRepuesto' component={EditarRepuesto} />
                    <PrivateRouteAdmin exact permisos={['FLOTAS']} path='/mantenedor/flotas' component={MantenedorFlotas} />
                    <PrivateRouteAdmin exact permisos={['FLOTAS']} path='/mantenedor/flotas/nuevocontenido' component={MantenedorFlotasNuevoContenido} />
                    <PrivateRouteAdmin exact permisos={['FLOTAS']} path='/mantenedor/flotas/editarcontenido/:idSeccion' component={MantenedorFlotasEditarContenido} />
                    <PrivateRouteAdmin exact permisos={['MERC_PUBLICO']} path="/mantenedor/mercado-publico" component={lazy(() => import('../BackOffice/view/Mantenedores/mercadoPublico/index'))}/>
                    <PrivateRouteAdmin exact permisos={['MERC_PUBLICO']} path="/mantenedor/mercado-publico/crear" component={MercadoPublicoCrear}/>
                    <PrivateRouteAdmin exact permisos={['MERC_PUBLICO']} path="/mantenedor/mercado-publico/editar/:idVehiculo" component={lazy(() => import('../BackOffice/view/Mantenedores/mercadoPublico/editar/index'))}/>
                    <PrivateRouteAdmin exact permisos={['MERC_PUBLICO']} path="/mantenedor/mercado-publico/duplicar/:idVehiculo" component={lazy(() => import('../BackOffice/view/Mantenedores/mercadoPublico/duplicar/index'))}/>
                    <PrivateRouteAdmin exact permisos={['HISTORIAS']} path='/mantenedor/historias' component={Historias} />
                    <PrivateRouteAdmin exact permisos={['HISTORIAS']} path='/mantenedor/historia/crear' component={HistoriasNew} />
                    <PrivateRouteAdmin exact permisos={['HISTORIAS']} path='/mantenedor/historia/editar/:idHistoria' component={HistoriasEditar} />
                    <PrivateRouteAdmin exact permisos={['NOTICIAS']} path='/mantenedor/noticias' component={Noticias} />
                    <PrivateRouteAdmin exact permisos={['NOTICIAS']} path='/mantenedor/noticia/crear' component={NoticiasNew} />
                    <PrivateRouteAdmin exact permisos={['NOTICIAS']} path='/mantenedor/noticia/editar/:idArticulo' component={NoticiasEditar} />
                    <PrivateRouteAdmin exact permisos={['NUES_CLIENTES']} path='/mantenedor/clientes' component={Clientes} />
                    <PrivateRouteAdmin exact permisos={['NUES_CLIENTES']} path='/mantenedor/cliente/crear' component={ClientesNew} />
                    <PrivateRouteAdmin exact permisos={['NUES_CLIENTES']} path='/mantenedor/cliente/editar/:idSeccion' component={ClientesEditar} />
                    {/* PANTALLAS */}

                    {/* OUTLET */}
                    <PrivateRouteAdmin exact permisos={['TODOS']} path="/mantenedor/outlet/" component={lazy(() => import('../BackOffice/view/Mantenedores/outletTodos/index'))}/>
                    <PrivateRouteAdmin exact permisos={['INV_VEHICULOS']} path="/mantenedor/outlet/vehiculos" component={OutletMantenedor}/>
                    <PrivateRouteAdmin exact permisos={['INV_VEHICULOS']} path="/mantenedor/outlet/vehiculo/crear" component={CrearVehiculoOutlet}/>
                    <PrivateRouteAdmin exact permisos={['INV_VEHICULOS']} path="/mantenedor/outlet/vehiculo/editar/:idVehiculo" component={lazy(() => import('../BackOffice/view/Mantenedores/outlet/editar/index'))}/>
                    <PrivateRouteAdmin exact permisos={['INV_VEHICULOS']} path="/mantenedor/outlet/vehiculo/duplicar/:idVehiculo" component={lazy(() => import('../BackOffice/view/Mantenedores/outlet/duplicar/index'))}/>
                    <PrivateRouteAdmin exact permisos={['VEH_LEADRESER']} path='/mantenedor/outlet/vehiculos/transacciones/conleaddereserva' component={ReservaLead} />
                    <PrivateRouteAdmin exact permisos={['VEH_RESERVA']} path='/mantenedor/outlet/vehiculos/transacciones/conreservaenlinea' component={ReservaEnLinea} />
                    <PrivateRouteAdmin exact permisos={['INV_REPUESTOS']} path='/mantenedor/outlet/repuestos' component={OutletRepuestos} />
                    <PrivateRouteAdmin exact permisos={['INV_REPUESTOS']} path='/mantenedor/outlet/repuesto/crear' component={NuevoRepuestoOutlet} />
                    <PrivateRouteAdmin exact permisos={['INV_REPUESTOS']} path='/mantenedor/outlet/repuesto/editar/:idRepuesto' component={EditarRepuestoOutlet} />
                    <PrivateRouteAdmin exact permisos={['INV_REPUESTOS']} path='/mantenedor/outlet/repuesto/duplicar/:idRepuesto' component={CopiaRepuestoOutlet} />
                    <PrivateRouteAdmin exact permisos={['REP_COMPRA']} path='/mantenedor/outlet/repuestos/transacciones' component={TransaccionesRepuestos} />
                    {/* OUTLET */}

                    {/* FORMULARIOS */}
                    <PrivateRouteAdmin exact permisos={['COTIZADOR_VEH']} path='/mantenedor/cotizacion/vehiculo' component={Cotizaciones} />
                    <PrivateRouteAdmin exact permisos={['COTIZADOR_REP']} path='/mantenedor/cotizacion/repuesto' component={cotizadorRepuesto} />
                    <PrivateRouteAdmin exact permisos={['COTIZADOR_MAN']} path='/mantenedor/cotizacion/mantencion' component={cotizadorMantencion} />
                    <PrivateRouteAdmin exact permisos={['FOR_CONCESIONARIOS']} path='/mantenedor/formulario/concesionario' component={concesionarioF} />
                    <PrivateRouteAdmin exact permisos={['FOR_SERVTECNICO']} path='/mantenedor/formulario/servicio-tecnico' component={servicioTecnicoF} />
                    <PrivateRouteAdmin exact permisos={['FOR_REPUESTOS']} path='/mantenedor/formulario/repuestos' component={repuestosF} />
                    <PrivateRouteAdmin exact permisos={['COTIZADOR_FLOTAS']} path='/mantenedor/formulario/flotas' component={flotasF} />
                    <PrivateRouteAdmin exact permisos={['COTIZADOR_MERCPUB']} path='/mantenedor/formulario/mercado-publico' component={mercadoPublicoF} />
                    <PrivateRouteAdmin exact permisos={['FOR_SERVCLIENTE']} path='/mantenedor/formulario/servicio-cliente' component={servicioCliente} />
                    <PrivateRouteAdmin exact permisos={['FOR_CONTACTO']} path='/mantenedor/formulario/contactanos' component={ContactanosBackoffice} />
                    <PrivateRouteAdmin exact permisos={['FOR_USUARIOSREG']} path='/mantenedor/formulario/usuarios' component={UsuariosRegistrados} /> {/* USUARIOS CLIENTES REGISTRADOS */}
                    {/* FORMULARIOS */}

                    {/* CONFIGURACION */}
                    <PrivateRouteAdmin exact permisos={['USUARIOS_HYUNDAI']} path='/mantenedor/usuarios' component={UsuariosF} /> {/* USUARIOS HYUNDAI */}
                    <PrivateRouteAdmin exact permisos={['USUARIOS_HYUNDAI']} path='/mantenedor/usuario/crear' component={CrearUsuarioF} />
                    <PrivateRouteAdmin exact permisos={['USUARIOS_HYUNDAI']} path='/mantenedor/usuario/editar/:idUsuario' component={editarUsuarioF} />
                    <PrivateRouteAdmin exact permisos={['PERFILES']} path='/mantenedor/perfiles' component={PerfilesF} />
                    <PrivateRouteAdmin exact permisos={['PERFILES']} path='/mantenedor/perfil/editar/:idParametro' component={editarPerfilF} />
                    <PrivateRouteAdmin exact permisos={['PERFILES']} path='/mantenedor/perfil/crear' component={crearPerfilF} />
                    {/* CONFIGURACION */}

                    {/* OTROS */}
                    <PrivateRouteAdmin exact permisos={['POLITICAS']} path='/mantenedor/politicas' component={PoliticasSeguridad} />
                    <PrivateRouteAdmin exact permisos={['POLITICAS']} path='/mantenedor/derecho' component={DerechoRetracto} />
                    <PrivateRouteAdmin exact permisos={['TERMINOS']} path='/mantenedor/terminos' component={TerminosCondiciones} />
                    <PrivateRouteAdmin exact permisos={['TERMINOS']} path='/mantenedor/footer' component={Footer} />
                    {/* OTROS */}

                    <Route exact path='/mantenedor/banner' component={Banner} />

                    {/* REDIRECT 301 */}
                    {/* <Redirect exact status={301} from="/modelo/hd35" to={{ pathname: '/vehiculo/hd35', state: { status: 301 } }} /> */}
                    <RedirectWithStatus status={301} from="/modelo/hd35/" to="/vehiculo/hd35" />
                    <RedirectWithStatus status={301} from="/modelo/hd35-light/" to="/vehiculo/hd35-light" />
                    <RedirectWithStatus status={301} from="/modelo/solati-h350/" to="/vehiculo/solati-h350-pax" />
                    <RedirectWithStatus status={301} from="/modelo/furgon-de-carga-solati-h350-cargo-van/" to="/vehiculo/solati-h350-cargo-van" />
                    <RedirectWithStatus status={301} from="/modelo/mighty-ex9-camion-mediano/" to="/vehiculo/mighty-ex9" />
                    <RedirectWithStatus status={301} from="/modelo/hd65-max-camion-mediano/" to="/vehiculo/hd65-max" />
                    <RedirectWithStatus status={301} from="/modelo/ex8/" to="/vehiculo/mighty-ex8" />
                    <RedirectWithStatus status={301} from="/modelo/hd65-doble-cabina/" to="/vehiculo/hd65-max" />
                    <RedirectWithStatus status={301} from="/modelo/hd120/" to="/vehiculo/hd120" />
                    <RedirectWithStatus status={301} from="/modelos/comerciales-clase-b/" to="/vehiculos/comerciales-clase-b" />
                    <RedirectWithStatus status={301} from="/modelo/bus-county-hyundai/" to="/vehiculo/county" />
                    <RedirectWithStatus status={301} from="/modelo/ex6/" to="/vehiculo/mighty-ex6" />
                    <RedirectWithStatus status={301} from="/modelos/camiones-medianos/" to="/vehiculos/camiones" />
                    <RedirectWithStatus status={301} from="/modelo/xcient-gt-tracto-4x2/" to="/vehiculo/xcient-gt-tracto-4x2" />
                    <RedirectWithStatus status={301} from="/modelo/furgon-escolar-solati-h350/" to="/vehiculos/" />
                    <RedirectWithStatus status={301} from="/modelo/hd160-max/" to="/vehiculo/hd160-max" />
                    <RedirectWithStatus status={301} from="/modelo/hd170/" to="/vehiculos/" />
                    <RedirectWithStatus status={301} from="/modelos/tracto-camion/" to="/vehiculos/camiones" />
                    <RedirectWithStatus status={301} from="/modelo/hd65-dc/" to="/vehiculo/hd65-max" />
                    <RedirectWithStatus status={301} from="/modelo/solati-h350-cargo-van/" to="/vehiculo/solati-h350-cargo-van" />
                    <RedirectWithStatus status={301} from="/modelo/universe/" to="/vehiculo/universe" />
                    <RedirectWithStatus status={301} from="/wp-content/uploads/2019/10/hyundai-vehiculos-comerciales-listado-de-precios-tienda-convenio-marco-2019.pdf" to="/mercado-publico" />
                    <RedirectWithStatus status={301} from="/wp-content/uploads/2019/09/Ficha_Hyundai_HD78.pdf" to="/vehiculos/" />
                    <RedirectWithStatus status={301} from="/wp-content/uploads/2019/08/1548132-H35-ISOTERMICA-EQUIPO-FRIO.pdf" to="/vehiculos/" />
                    <RedirectWithStatus status={301} from="/modelo/nuevo-xcient-gt-tracto-6x4/" to="/vehiculo/xcient-gt-tracto-6x4" />
                    <RedirectWithStatus status={301} from="/concesionarios/detalle-concesionario/?id=31" to="/concesionario/tecnosur/valdivia" />
                    <RedirectWithStatus status={301} from="/concesionarios/detalle-concesionario/?id=28" to="/concesionario/comercial-copelec/chillan" />
                    <RedirectWithStatus status={301} from="/modelo/nuevo-xcient-gt-faena/" to="/vehiculo/xcient-gt-faena-8x4" />
                    <RedirectWithStatus status={301} from="/modelo/recolector-camiones-aplicaciones-especiales/" to="/vehiculos/" />
                    <RedirectWithStatus status={301} from="/concesionarios/detalle-concesionario/?id=34" to="/concesionarios/" />
                    <RedirectWithStatus status={301} from="/concesionarios/detalle-concesionario/?id=35" to="/concesionarios/" />
                    <RedirectWithStatus status={301} from="/venta-de-flotas/" to="/flotas/" />
                    <RedirectWithStatus status={301} from="/modelo/estanque-combustible/" to="/vehiculos/" />
                    <RedirectWithStatus status={301} from="/modelo/ex9/" to="/vehiculo/mighty-ex9" />
                    <RedirectWithStatus status={301} from="/modelo/ex18-pavise-mt/" to="/vehiculo/ex18-pavise" />
                    <RedirectWithStatus status={301} from="/modelos/camiones-pesados/" to="/vehiculos/camiones" />
                    <RedirectWithStatus status={301} from="/mercado-publico/solati-h350-ambulancia-4x2-aeb" to="/mercado-publico/" />
                    <RedirectWithStatus status={301} from="/mercado-publico/h-350-furgon-solati-cargo-van-carga-refrigerada" to="/mercado-publico/" />
                    <RedirectWithStatus status={301} from="/vehiculo/hd160-max" to="/vehiculos/" />
                    <RedirectWithStatus status={301} from="/modelo/" to="/vehiculos/" />
                    <Redirect to="/" />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}

export default Routes;
