import React from 'react';
import { Redirect, Route } from "react-router-dom";

function RedirectWithStatus({ from, to, status }) {
    return (
      <Route
        render={({ staticContext }) => {
          // there is no `staticContext` on the client, so
          // we need to guard against that here
          if (staticContext) staticContext.status = status;
          return <Redirect exact strict from={from} to={to} />;
        }}
      />
    );
  }

  export default RedirectWithStatus