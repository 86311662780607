
export const rolesPermitidos = (allowRoles) => {

  let respuesta = true;

  if (allowRoles[0] !== "*") {

    let userPermisos = JSON.parse( sessionStorage.getItem('usuarioBackoffice') );
    userPermisos = userPermisos.Permisos;

    //Permisos trae "idPermiso": 308, "Perfil": "PERFILTEST", "Modulo": "PANTALLAS", "Seccion": "HOME",
    //"Escritura": 0,"Lectura": 0,"Eliminacion": 0

    let permisos = userPermisos.filter((element) => element.Lectura === 1).map((element) => element.Seccion); // "HOME"
    let respuestaPermisos = allowRoles.map((element) => {
      return permisos.includes(element);
    });

    if (respuestaPermisos.includes(true) === false) respuesta = false;
    /* roles de acceso,estados,tipodoc,interactuar consultas, probar errores,*/
  }

  return respuesta;
};

export const escrituraPermitida = (acceso) => {

  let respuesta = 0;

  let userPermisos = JSON.parse( sessionStorage.getItem('usuarioBackoffice') );
  userPermisos = userPermisos.Permisos;

  let permisos = userPermisos.filter((element) => element.Escritura === 1).map((element) => element.Seccion);
  let respuestaPermiso = permisos.includes(acceso);

  respuestaPermiso ? (respuesta = true) : (respuesta = false);

  return respuesta;
};

export const lecturaPermitida = (acceso) => {

  let respuesta = 0;

  let userPermisos = JSON.parse( sessionStorage.getItem('usuarioBackoffice') );
  userPermisos = userPermisos.Permisos;

  let permisos = userPermisos.filter((element) => element.Lectura === 1).map((element) => element.Seccion);
  let respuestaPermiso = permisos.includes(acceso);

  respuestaPermiso ? (respuesta = true) : (respuesta = false);

  return respuesta;
};

export const eliminacionPermitida = (acceso) => {

  let respuesta = 0;

  let userPermisos = JSON.parse( sessionStorage.getItem('usuarioBackoffice') );
  userPermisos = userPermisos.Permisos;

  let permisos = userPermisos.filter((element) => element.Eliminacion === 1).map((element) => element.Seccion);
  let respuestaPermiso = permisos.includes(acceso);

  respuestaPermiso ? (respuesta = true) : (respuesta = false);

  return respuesta;
};
