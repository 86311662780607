import React from 'react';
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import './css/global.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import 'antd/dist/antd.css';
import 'lazysizes/lazysizes';
import * as serviceWorker from "./serviceWorker";
import Routes from "./routes";
import { Provider } from 'react-redux';
import store from './Front/redux/store/store';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie'

let hcbCookies = Cookies.get('hcb-cookie');

if (!hcbCookies){
  Cookies.set('hcb-analytics', true)
  Cookies.set('hcb-marketing', true)
}

const tagManagerArgs = {
  gtmId: 'GTM-PS2FSH2'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider store={store}  >
    <Routes />
  </Provider>, document.getElementById("contenedor-general")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();