import axios from 'axios'

//http://localhost:8080/ 
//http://apiqa.hyundaicamionesybuses.cl/
const clienteAxios = axios.create({
    baseURL: 'https://api.hyundaicamionesybuses.cl/',
    headers: {
        'Authorization': `${process.env.REACT_APP_BEARER_TOKEN}`
      }
})

export default clienteAxios