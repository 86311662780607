import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { rolesPermitidos } from "../helper/loginValidation";

// const PrivateRouteAdmin = ({ permisos: Permisos, component: Component, ...props }) => {
const PrivateRouteAdmin = ({ permisos: Permisos, component: Component, ...props }) => {

  //Revisamos si el usuario esta loggeado
  const logged = JSON.parse( sessionStorage.getItem('usuarioBackoffice') );

  return (
    <Route
      {...props} render={(props) => logged !== null ? (
          rolesPermitidos(Permisos) ? (
            <Component {...props} />
          ) : (
            //Si no esta loggeado lo redireccionamos al home
            <Redirect to="/404" />
          )

        )
        : (
          <Redirect to="/mantenedor/login" />
        )
      }
    />
  );
};

export default PrivateRouteAdmin;